<template>
  <div>
    <global-header></global-header>
    <!--  轮播图  -->
    <div class="carousel mar-b10">
      <el-carousel
        indicator-position="none">
        <el-carousel-item
          v-for="item in swiperConfig.bannerList" :key="item">
          <el-image
            fit="cover"
            :src="item"
            style="height: 100%;width: 100%;"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
      <div class="carousel-note">
        <div class="container flex flex-center">
          <div v-for="item in swiperConfig.note" class="flex-item1 note-item">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <!--  第二部分  -->
    <div class="content__two mar-b10">
      <div class="container">
        <div class="flex ">
          <div class="flex flex-col left-menu">
            <div @mouseenter="activeContentTwo = 1" :class="[{active: activeContentTwo === 1}]"
                 class="flex menu-item"
                 style="padding: 10px;">
              <el-image class="img-left"
                        src="/images/index/imgl1.png"
              ></el-image>
              <div class="title">
                <div class="title-inner">多渠道客服解决方案</div>
              </div>
            </div>
            <div @mouseenter="activeContentTwo = 2" :class="[{active: activeContentTwo === 2}]"
                 class="flex menu-item"
                 style="padding: 10px;">
              <el-image class="img-left"
                        src="/images/index/imgl2.png"
              ></el-image>
              <div class="title">
                <div class="title-inner">智能问题应答</div>
              </div>
            </div>
            <div @mouseenter="activeContentTwo = 3" :class="[{active: activeContentTwo === 3}]"
                 class="flex menu-item" style="padding: 10px;">
              <el-image class="img-left"
                        src="/images/index/imgl3.png"
              ></el-image>
              <div class="title">
                <div class="title-inner">提高客服效率与服务</div>
              </div>
            </div>
            <div @mouseenter="activeContentTwo = 4" :class="[{active: activeContentTwo === 4}]"
                 class="flex menu-item" style="padding: 10px;">
              <el-image class="img-left"
                        src="/images/index/imgl4.png"
              ></el-image>
              <div class="title">
                <div class="title-inner">长期技术支持</div>
              </div>
            </div>
          </div>
          <div class="right-content">
            <div :style="{display: activeContentTwo === 1 ? 'block' : 'none'}">
              <div class="two-fs">解决网站、小程序等多个渠道客服集中响应，提供客户服务质量。</div>
              <el-image
                src="/images/index/imgr1.png"
              ></el-image>
            </div>
            <div :style="{display: activeContentTwo === 2 ? 'block' : 'none'}">
              <div class="two-fs">根据用户的输入的问题，智能匹配应答问题及回复内容，大量节约人工客服数量</div>
              <el-image
                src="/images/index/imgr2.png"
              ></el-image>
            </div>
            <div :style="{display: activeContentTwo === 3 ? 'block' : 'none'}">
              <div class="two-fs">智能应答解决大部分客户的问题，预计能提高50%以上的效率，大量减少人力成本支出</div>
              <el-image
                src="/images/index/imgr3.png"
              ></el-image>
            </div>
            <div :style="{display: activeContentTwo === 4 ? 'block' : 'none'}">
              <div class="two-fs">专注于客服系统的开发，提供长期的技术支持服务，支持7*8小时的在线支持</div>
              <el-image
                src="/images/index/imgr4.png"
              ></el-image>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  第三部分  -->
    <div class="content__three mar-b10">
      <div class="container">
        <div class="title">
          <div class="title-inner">客服AI资讯</div>
        </div>
        <el-row :gutter="10">
          <template v-for="item in swiperConfig.aiList">
            <router-link :to="'/showAiDetails?id='+item.id" target="_blank">
              <el-col :span="6">
                <el-image
                  :src="item.newsimgurl"
                  fit="cover"
                  style="width: 100%;height: 100%;"
                ></el-image>
              </el-col>
            </router-link>
          </template>
        </el-row>
      </div>
    </div>
    <footer-links></footer-links>
    <global-footer></global-footer>
  </div>
</template>

<script>
  var c = document.createElement('script');
  var url = window.location.hostname;
  c.src = "https://web01.aiocs.cn/srapi/vclientV3?tokenkey=LEkf6G6uL2pP9DOH%2FEsrX%2BA%2BHVJiKwGci8jqIExOECTScVDhwalOEMk1%2BxXPT97h4kfcE0nQWL%2FdRqpusblHK9CbdeTg5pW1AVvZYDhd4bE%3D&website="+url;
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(c, s);
</script>

<script>
import {getCarousel} from '@/apis/index'

  export default {
    name: "index",
    components: {
      GlobalHeader: () => import('@PAGE/common/GlobalHeader'),
      GlobalFooter: () => import('@PAGE/common/GlobalFooter'),
      FooterLinks: () => import('@PAGE/common/FooterLinks'),
    },
    data() {
      return {
        swiperConfig: {
          bannerList: [],
          aiList: [],
          note: [
            '专注客服系统开发',
            '永久技术支持',
            '支持大量用户访问',
            '集成方便无需安装',
          ],
          loadData: () => {
          },
        },
        activeContentTwo: 1,
      }
    },
    created() {
      this.getCarousel();
    },
    methods: {
      getCarousel() {
        getCarousel().then(res => {
          if (res.retCode == 8888) {
            this.swiperConfig.bannerList = res.retdata.bannerList;
            this.swiperConfig.aiList = res.retdata.aiList;
          }
        })
      }
    },
    mounted() {
    },
  }
</script>
<style>
  img {
    object-fit: initial !important;
  }
</style>
<style scoped lang="scss">
  .two-fs {
    font-size: 20px;
    padding-top: 20px;
  }

  ::v-deep {
    $carousel-height: 600px;

    .carousel {
      height: $carousel-height;
      position: relative;

      .el-carousel__container {
        height: $carousel-height;
      }

      .carousel-note {
        z-index: 9;
        width: 100%;
        position: absolute;
        height: 40px;
        background: $main-color;
        bottom: 0;
        color: white;
        font-size: 14px;

        .container {
          height: 100%;

          .note-item:not(:first-child):before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            border-left: 1px white solid;
          }

          .note-item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .content__two {
      .container {
        .left-menu {
          width: 350px;
          color: #545d69;

          .menu-item:not(:first-child) {
            border-top: none !important;
          }

          .menu-item {
            background-color: #fff;
            box-sizing: border-box;
            border: 1px solid #e2e9ea;

            &.active {
              position: relative;
              color: white;
              background: linear-gradient(to left, #3955cd, #6378d2);

              &:after {
                position: absolute;
                content: '';
                display: block;
                border-width: 10px;
                border-style: solid;
                border-color: transparent;
                border-left-color: #3955cd;
                right: 0;
                top: 50%;
                transform: translateY(-50%) translateX(100%);
              }
            }

            i[class*=el-icon] {
              font-size: 35px;
            }

            .title {
              padding-top: 5px;
              margin-left: 15px;

              .title-inner {
                font-size: 16px;
                line-height: 100px;
              }
            }
          }

        }

        .right-content {
          margin-left: 80px;
        }
      }
    }

    .content__three {
      .container {

        .title {
          padding: 10px 15px;

          .title-inner {
            font-size: 20px;
          }
        }

        [class*=el-col-] {
          height: 200px;
        }
      }
    }
  }

  .img-left {
    width: 100px;
  }
</style>
